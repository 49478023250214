import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, FormControl, Grid, IconButton, Table, TableBody, TableCell, TableRow, Tooltip, Typography } from '@material-ui/core';
import { Delete, Notifications, NotificationsOff } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import axios from 'axios';
import i18next from 'i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { postToEndpointWithToken } from '../AxiosHelper';
import { DeviceDescriptor } from '../Device';
import { coordinateFormatter, getDeviceStateForDeviceId, getDeviceStatusColor, getLocalDateStringFromUtcDateString, getOrderedOldDeviceDescriptors, getOrderedRecentDeviceDescriptors, isEmailAddressValid } from '../Helper';
import { MonitoringSystemDescriptor, MonitoringSystemState } from '../MonitoringSystem';
import { User } from '../User';
import { AccordionWithTextFieldAndButton } from '../components/AccordionWithTextFieldAndButton';
import { useStyles } from '../styles';
import BatteryIcon from './BatteryIcon';
import EditableText from './EditableText';
import './MonitoringSystemPage.css';

interface MonitoringSystemPageProps {
  token: string,
  user: User,
  selectedMonitoringSystemDescriptor: MonitoringSystemDescriptor,
  selectedMonitoringSystemState: MonitoringSystemState,
  updateMonitoringSystemDescriptorRequest: () => void,
  onLogout: () => void
}

function MonitoringSystemPage(props: MonitoringSystemPageProps): JSX.Element {
  const { token, user, selectedMonitoringSystemDescriptor, selectedMonitoringSystemState, updateMonitoringSystemDescriptorRequest, onLogout } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const addingCodeFromUrl = new URLSearchParams(location.search).get('addingCode');

  const [monitoringDaysExpanded, setMonitoringDaysExpanded] = useState<boolean>(false);
  const [devicesExpanded, setDevicesExpanded] = useState<boolean>(true);//open by default
  const [guestsExpanded, setGuestsExpanded] = useState<boolean>(false);
  const [deviceExpandedIndex, setDeviceExpandedIndex] = useState<number | undefined>(undefined);

  const [isSendDeviceAccordionExpanded, setIsSendDeviceAccordionExpanded] = useState<boolean>(false);
  const [isRemovingGuest, setIsRemovingGuest] = useState<boolean>(false);
  const [leaveErrorLabel, setLeaveErrorLabel] = useState<string>('');

  //close all the device accordions when their main accordion changes
  useEffect(() => {
    setDeviceExpandedIndex(undefined);
  }, [devicesExpanded]);

  //close send device accordion if the device expanded index changes
  useEffect(() => {
    setIsSendDeviceAccordionExpanded(false);
  }, [deviceExpandedIndex]);

  //TODO validate guest email

  const orderedGuests = useMemo(() => {
    return selectedMonitoringSystemDescriptor.guests.sort((g1, g2) => g1.email.localeCompare(g2.email));
  }, [selectedMonitoringSystemDescriptor.guests])

  const orderedRecentDeviceDescriptors = useMemo(() => getOrderedRecentDeviceDescriptors(selectedMonitoringSystemDescriptor, selectedMonitoringSystemState), [selectedMonitoringSystemDescriptor, selectedMonitoringSystemState]);
  const orderedOtherDeviceDescriptors = useMemo(() => getOrderedOldDeviceDescriptors(selectedMonitoringSystemDescriptor, selectedMonitoringSystemState), [selectedMonitoringSystemDescriptor, selectedMonitoringSystemState]);

  const handleTopupClick = useCallback((topupCode: string): Promise<void> => {
    return postToEndpointWithToken('topupMonitoringSystem', token, { code: topupCode })
      .then(() => {
        updateMonitoringSystemDescriptorRequest();
      })
      .catch((error) => {
        const response = error.response;
        let errorLabel = 'monitoring_system_page.topup.fail.unknown_reason';
        if (axios.isAxiosError(error)) {
          if (response?.status === 401 || response?.status === 403) {
            onLogout();
          } else if (response?.status === 404 || response?.status === 412) {
            errorLabel = 'monitoring_system_page.topup.fail.bad_code';
          } else if (response?.status === 409) {
            errorLabel = 'monitoring_system_page.topup.fail.used_code';
          }
        }
        return Promise.reject(errorLabel);
      })
  }, [onLogout, token, updateMonitoringSystemDescriptorRequest])

  const handleAddDeviceClick = useCallback((addingCode: string): Promise<void> => {
    return postToEndpointWithToken('addDevice', token, { addingCode: addingCode })
      .then(() => {
        updateMonitoringSystemDescriptorRequest();
      })
      .catch((error) => {
        const response = error.response;
        let errorLabel = 'monitoring_system_page.add_device.fail.unknown_reason';
        if (axios.isAxiosError(error)) {
          if (response?.status === 401 || response?.status === 403) {
            onLogout();
          } else if (response?.status === 404) {
            errorLabel = 'monitoring_system_page.add_device.fail.bad_code';
          } else if (response?.status === 409) {
            errorLabel = 'monitoring_system_page.add_device.fail.used_code';
          }
        }
        return Promise.reject(errorLabel);
      })
  }, [onLogout, token, updateMonitoringSystemDescriptorRequest])

  const handleAddGuestClick = useCallback((newGuestEmail: string): Promise<void> => {
    return postToEndpointWithToken('inviteGuest', token, { email: newGuestEmail })
      .then(() => {
        updateMonitoringSystemDescriptorRequest();
      })
      .catch((error) => {
        const response = error.response;
        let errorLabel = 'monitoring_system_page.guests.fail.unknown_reason';
        if (axios.isAxiosError(error)) {
          if (response?.status === 401 || response?.status === 403) {
            onLogout();
          } else if (response?.status === 404) {
            errorLabel = 'monitoring_system_page.guests.fail.not_exist';
          } else if (response?.status === 409) {
            errorLabel = 'monitoring_system_page.guests.fail.already_added';
          }
        }
        return Promise.reject(errorLabel);
      })
  }, [onLogout, token, updateMonitoringSystemDescriptorRequest])

  const handleRemoveGuestClick = useCallback((email: string) => {
    setIsRemovingGuest(true);
    postToEndpointWithToken('removeGuest', token, { email: email })
      .then(() => {
        updateMonitoringSystemDescriptorRequest();
      })
      .catch((error) => {
        const response = error.response;
        let errorLabel = 'monitoring_system_page.guests.fail.remove';
        if (axios.isAxiosError(error)) {
          if (response?.status === 401 || response?.status === 403) {
            onLogout();
          }
        }
        console.log(errorLabel); //TODO find a better way to show this error to the user
      })
      .finally(() => setIsRemovingGuest(false))
  }, [onLogout, token, updateMonitoringSystemDescriptorRequest]);

  const onLeave = useCallback(() => {
    postToEndpointWithToken('leaveMonitoringSystem', token, { monitoringSystemId: selectedMonitoringSystemDescriptor.monitoringSystemId })
      .then(() => {
        setLeaveErrorLabel('');
        updateMonitoringSystemDescriptorRequest();
      })
      .catch((error) => {
        const response = error.response;
        let errorLabel = 'monitoring_system_page.guests.fail.leave';
        if (axios.isAxiosError(error)) {
          if (response?.status === 401 || response?.status === 403) {
            onLogout();
          }
        }
        setLeaveErrorLabel(errorLabel);
      })
  }, [onLogout, selectedMonitoringSystemDescriptor.monitoringSystemId, token, updateMonitoringSystemDescriptorRequest]);

  //close the expanded item when changing between monitoring systems (but do not close when only a property of the same monitoring system changes)
  useEffect(() => {
    if (selectedMonitoringSystemDescriptor?.monitoringSystemId !== undefined) {
      setDeviceExpandedIndex(undefined);
    }
  }, [selectedMonitoringSystemDescriptor?.monitoringSystemId])

  useEffect(() => {
    if (addingCodeFromUrl !== null) {
      setTimeout(() => {
        window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' });
      }, 200); // Timeout needed for the accordion to open and the stuff to be rendered
    }
  }, [addingCodeFromUrl])

  const handleDeviceRename = useCallback((index: number, newName: string) => {
    const deviceId = index > orderedRecentDeviceDescriptors.length - 1 ? orderedOtherDeviceDescriptors[index - orderedRecentDeviceDescriptors.length].deviceId : orderedRecentDeviceDescriptors[index].deviceId;
    postToEndpointWithToken('renameDevice', token, { deviceId: deviceId, name: newName })
      .then(() => {
        updateMonitoringSystemDescriptorRequest();
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          //the only reason for an error here is that we are logged out -> we log out
          onLogout();
        }
      })
  }, [onLogout, orderedOtherDeviceDescriptors, orderedRecentDeviceDescriptors, token, updateMonitoringSystemDescriptorRequest]);

  const handleIgnoreClick = useCallback((deviceId: number, toIgnore: boolean) => () => {
    postToEndpointWithToken('ignoreDevice', token, { devicesToIgnore: [{ deviceId: deviceId, toIgnore: toIgnore }] })
      .then(() => {
        updateMonitoringSystemDescriptorRequest();
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          //the only reason for an error here is that we are logged out -> we log out
          onLogout();
        }
      })
  }, [onLogout, token, updateMonitoringSystemDescriptorRequest]);

  const handleSendDeviceClick = useCallback((email: string, deviceId: number): Promise<void> => {
    return postToEndpointWithToken('sendDevice', token, { email: email, deviceId: deviceId })
      .then(() => {
        updateMonitoringSystemDescriptorRequest();
      })
      .catch((error) => {
        const response = error.response;
        let errorLabel = 'monitoring_system_page.send_device.fail.unknown_reason';
        if (axios.isAxiosError(error)) {
          if (response?.status === 401 || response?.status === 403) {
            onLogout();
          } else if (response?.status === 404) {
            errorLabel = 'monitoring_system_page.send_device.fail.not_exist';
          }
        }
        return Promise.reject(errorLabel);
      })
  }, [onLogout, token, updateMonitoringSystemDescriptorRequest])

  const handleAcceptClick = useCallback((deviceId: number): Promise<void> => {
    return postToEndpointWithToken('acceptDevice', token, { deviceId: deviceId })
      .then(() => {
        updateMonitoringSystemDescriptorRequest();
      })
      .catch((error) => {
        const response = error.response;
        let errorLabel = 'monitoring_system_page.accept_device.fail.unknown_reason';
        if (axios.isAxiosError(error)) {
          if (response?.status === 401 || response?.status === 403) {
            onLogout();
          }
        }
        return Promise.reject(errorLabel);
      })
  }, [onLogout, token, updateMonitoringSystemDescriptorRequest])

  const handleCancelSendClick = useCallback((deviceId: number): Promise<void> => {
    return postToEndpointWithToken('cancelDeviceSend', token, { deviceId: deviceId })
      .then(() => {
        updateMonitoringSystemDescriptorRequest();
      })
      .catch((error) => {
        const response = error.response;
        let errorLabel = 'monitoring_system_page.cancel_send_device.fail.unknown_reason';
        if (axios.isAxiosError(error)) {
          if (response?.status === 401 || response?.status === 403) {
            onLogout();
          }
        }
        return Promise.reject(errorLabel);
      })
  }, [onLogout, token, updateMonitoringSystemDescriptorRequest])


  const BUDGET_WARN_DAYS = 14;
  const budgetDaysLeft = selectedMonitoringSystemState.monitoringDaysBudget / Math.max(orderedRecentDeviceDescriptors.length, 1);
  const budgetColor = selectedMonitoringSystemState.monitoringDaysBudget <= 0 ? "#d32f2f" : (budgetDaysLeft <= BUDGET_WARN_DAYS ? "#ff9800" : "rgba(0, 0, 0, 0.87)");

  const getDeviceGridItems = useCallback((deviceDescriptors: DeviceDescriptor[], startIndex: number) => {
    return deviceDescriptors.map((deviceDescriptor, index) => {
      const deviceState = getDeviceStateForDeviceId(deviceDescriptor.deviceId, selectedMonitoringSystemState);
      const idx = index + startIndex;
      return <Grid item style={{ margin: 5 }} key={deviceDescriptor.deviceId}>
        {deviceDescriptor.sentTo === user.email ?
          <Button
            onClick={() => handleAcceptClick(deviceDescriptor.deviceId)}
            variant="contained"
            color="primary"
            fullWidth
          >
            {t('monitoring_system_page.accept_device.btn', { deviceId: deviceDescriptor.deviceId })}
          </Button> :
          deviceDescriptor.sentTo !== null && deviceDescriptor.sentTo !== '' ?
            <Button
              onClick={() => handleCancelSendClick(deviceDescriptor.deviceId)}
              variant="contained"
              color="primary"
              fullWidth
            >
              {t('monitoring_system_page.cancel_send_device.btn', { deviceId: deviceDescriptor.deviceId })}
            </Button> :
            <Accordion
              expanded={deviceExpandedIndex === idx}
              onChange={() => setDeviceExpandedIndex(prev => prev === undefined ? idx : prev === idx ? undefined : idx)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: deviceDescriptor.isIgnored ? 'lightgray' : undefined }}>
                <Grid container alignItems='center' justifyContent='center'>
                  <Grid item xs={1}>
                    {deviceDescriptor.isIgnored ? <NotificationsOff /> : (deviceState !== undefined && <span className={"circle " + getDeviceStatusColor(deviceState.status) + "-circle"}></span>)}
                  </Grid>
                  <Grid item xs={2}>
                    {!deviceDescriptor.isIgnored && deviceState !== undefined && BatteryIcon(deviceState.battery)}
                  </Grid>
                  <Grid item xs={9}>
                    {selectedMonitoringSystemDescriptor.owner.email === user.email ?
                      <EditableText value={deviceDescriptor.name} onSave={(newName) => handleDeviceRename(idx, newName)} /> :
                      <Typography>{deviceDescriptor.name}</Typography>}
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails
                style={{ backgroundColor: deviceDescriptor.isIgnored ? 'lightgray' : undefined }}
                title={deviceDescriptor.isIgnored ? t('device.ignored_title') : ''}
              >
                <Table size='small'>
                  <TableBody>
                    <TableRow>
                      <TableCell variant="body" width={"60%"}>{t('device.serial')}</TableCell>
                      <TableCell variant="body">{deviceDescriptor.deviceId}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="body">{t('device.status.title')}</TableCell>
                      <TableCell variant="body">{t('device.status.' + deviceState?.status.toLowerCase())}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="body">{t('device.location.title')}</TableCell>
                      <TableCell variant="body">
                        {deviceState?.latitude === null || deviceState?.latitude === undefined ? t('device.location.no_location') :
                          <>
                            {t('device.location.latitude', { latitude: coordinateFormatter(i18next.language).format(deviceState.latitude) })}
                            <br />
                            {t('device.location.longitude', { longitude: coordinateFormatter(i18next.language).format(deviceState.longitude!) })}
                            <br />
                            <Link to={`/map?location=${deviceState?.latitude},${deviceState?.longitude}`}>
                              {t('device.go_to_map')}
                            </Link>
                          </>
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="body">{t('device.battery')}</TableCell>
                      <TableCell variant="body">{deviceState?.battery}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="body">{t('device.temperature')}</TableCell>
                      <TableCell variant="body">{deviceState?.temperature}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="body">{t('device.signal_strength')}</TableCell>
                      <TableCell variant="body">{deviceState?.signalStrength}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="body">{t('device.last_seen')}</TableCell>
                      <TableCell variant="body">{deviceState === undefined ? undefined : getLocalDateStringFromUtcDateString(deviceState.lastSeen, i18next.language)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="body">{t(deviceDescriptor.isIgnored ? 'device.ignored' : 'device.not_ignored')}</TableCell>
                      <TableCell variant="body">{
                        <IconButton
                          title={deviceDescriptor.isIgnored ? t('device.unignore') : t('device.ignore')}
                          onClick={handleIgnoreClick(deviceDescriptor.deviceId, !deviceDescriptor.isIgnored)}
                        >
                          {deviceDescriptor.isIgnored ? <Notifications /> : <NotificationsOff />}
                        </IconButton>
                      }</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="body" colSpan={2}>
                        <AccordionWithTextFieldAndButton
                          disabled={false}
                          buttonText={t('monitoring_system_page.send_device.btn')}
                          buttonTooltip={t('monitoring_system_page.send_device.btn_tooltip')}
                          expanded={isSendDeviceAccordionExpanded}
                          onExpandedChange={() => setIsSendDeviceAccordionExpanded(prev => !prev)}
                          onClick={(email) => handleSendDeviceClick(email, deviceDescriptor.deviceId)}
                          validateText={(email) => email === '' || isEmailAddressValid(email) ? '' : t('monitoring_system_page.send_device.invalid_email')}
                          summaryTooltip={""}
                          summaryTextLeft={t('monitoring_system_page.send_device.title')}
                          textfieldEmptyText={t('monitoring_system_page.send_device.input') + ' *'}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </AccordionDetails>
            </Accordion>}
      </Grid>
    });
  }, [deviceExpandedIndex, handleAcceptClick, handleCancelSendClick, handleDeviceRename, handleIgnoreClick, handleSendDeviceClick, isSendDeviceAccordionExpanded, selectedMonitoringSystemDescriptor.owner.email, selectedMonitoringSystemState, t, user.email]);


  const orderedRecentDeviceGridItems = useMemo(() => getDeviceGridItems(orderedRecentDeviceDescriptors, 0),
    [orderedRecentDeviceDescriptors, getDeviceGridItems]);
  const orderedOtherDeviceGridItems = useMemo(() => getDeviceGridItems(orderedOtherDeviceDescriptors, orderedRecentDeviceDescriptors.length),
    [orderedOtherDeviceDescriptors, getDeviceGridItems, orderedRecentDeviceDescriptors.length]);

  return <FormControl className={classes.mainFrame}>
    <Typography variant="h5" className={classes.pageTitle}>
      <Trans>{selectedMonitoringSystemDescriptor.name}</Trans>
    </Typography>
    <Grid container className={classes.mainGridContainer}>
      <Grid item style={{ marginTop: 10 }}>
        <AccordionWithTextFieldAndButton
          disabled={false}
          summaryTooltip={budgetDaysLeft <= 0 ? t('monitoring_system_page.topup.no_budget_tooltip') : budgetDaysLeft <= BUDGET_WARN_DAYS ? t('monitoring_system_page.topup.warn_budget_tooltip', { daysLeft: budgetDaysLeft }) : ""}
          summaryTextLeft={t('monitoring_system_page.topup.budget')}
          summaryCounter={selectedMonitoringSystemState.monitoringDaysBudget}
          summaryCounterColor={budgetColor}
          expanded={monitoringDaysExpanded}
          onExpandedChange={() => setMonitoringDaysExpanded(prev => { return !prev; })}
          onClick={handleTopupClick}
          textfieldEmptyText={t('monitoring_system_page.topup.input') + ' *'}
          buttonText={t('monitoring_system_page.topup.btn')}
          buttonTooltip={t('monitoring_system_page.topup.btn_tooltip')}
          clickableLink='https://shop.feedbackpack.eu/products/100-megfigyelesi-nap'
          clickableLinkText={t('monitoring_system_page.topup.webshop_link')}
        />
      </Grid>
      <Grid item>
        <AccordionWithTextFieldAndButton
          disabled={false}
          summaryTooltip={t('monitoring_system_page.add_device.tooltip')}
          summaryTextLeft={t('monitoring_system_page.devices_title')}
          summaryCounter={selectedMonitoringSystemDescriptor.deviceDescriptors.length}
          expanded={devicesExpanded}
          onExpandedChange={() => setDevicesExpanded(prev => !prev)}
          onClick={handleAddDeviceClick}
          textfieldEmptyText={t('monitoring_system_page.add_device.input_help') + ' *'}
          buttonText={t('monitoring_system_page.add_device.btn')}
          buttonTooltip={t('monitoring_system_page.add_device.btn_tooltip')}
          clickableLink='https://shop.feedbackpack.eu/products/solo-sam-vadcsapda-megfigyelo-120-ajandek-megfigyelesi-nappal'
          clickableLinkText={t('monitoring_system_page.add_device.webshop_link')}
          children={orderedRecentDeviceGridItems.length === 0 ?
            <Grid container direction='column'>
              {orderedOtherDeviceGridItems}
            </Grid> : orderedOtherDeviceGridItems.length === 0 ?
              <Grid container direction='column'>
                {orderedRecentDeviceGridItems}
              </Grid>
              : <div>
                <Grid container direction='column'>
                  <Grid item>
                    <Typography align='center' style={{ fontWeight: 'bold', margin: 5 }}>
                      {t('monitoring_system_page.recent_devices_title')}
                    </Typography>
                  </Grid>
                  <Grid container>
                    {orderedRecentDeviceGridItems}
                  </Grid>
                </Grid>
                <Grid container direction='column'>
                  <Grid item>
                    <Typography align='center' style={{ fontWeight: 'bold', marginTop: 10, marginBottom: 5 }}>
                      {t('monitoring_system_page.old_devices_title')}
                    </Typography>
                  </Grid>
                  <Grid container>
                    {orderedOtherDeviceGridItems}
                  </Grid>
                </Grid>
              </div>
          }
        />
      </Grid>
      <Grid item>
        {user.email === selectedMonitoringSystemDescriptor.owner.email ?
          <AccordionWithTextFieldAndButton
            disabled={false}
            summaryTooltip={t('monitoring_system_page.guests.tooltip')}
            summaryTextLeft={t('monitoring_system_page.guests.title')}
            summaryCounter={selectedMonitoringSystemDescriptor.guests.length}
            expanded={guestsExpanded}
            onExpandedChange={() => setGuestsExpanded(prev => !prev)}
            onClick={handleAddGuestClick}
            textfieldEmptyText={t('monitoring_system_page.guests.input') + ' *'}
            buttonText={t('monitoring_system_page.guests.btn')}
            buttonTooltip={t('monitoring_system_page.guests.btn_tooltip')}
            validateText={(email) => email === '' || isEmailAddressValid(email) ? '' : t('monitoring_system_page.guests.invalid_email')}
            children={
              selectedMonitoringSystemDescriptor.guests.length > 0 ?
                <Grid item>
                  {
                    orderedGuests.map((guest) => {
                      return <Grid container alignItems='center' justifyContent='space-between'>
                        <Grid item>
                          <Typography style={{ marginLeft: 15 }}>{guest.email}</Typography>
                        </Grid>
                        <Grid item>
                          <Tooltip title={t('monitoring_system_page.guests.delete_btn_tooltip')}>
                            <span>
                              <IconButton onClick={() => handleRemoveGuestClick(guest.email)} >
                                {isRemovingGuest ? <CircularProgress /> : <Delete />}
                              </IconButton>
                            </span>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    })
                  }
                </Grid> : undefined
            }
          />
          :
          <Grid container alignItems='center' justifyContent='center' direction='column'>
            <Grid item>
              <Typography style={{ color: 'red' }}>
                {t(leaveErrorLabel)}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={() => onLeave()}
              >
                {t('monitoring_system_page.guests.leave')}
              </Button>
            </Grid>
          </Grid>
        }
      </Grid>
    </Grid >
  </FormControl >
}

export default MonitoringSystemPage;
