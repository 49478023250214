import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Grid, Link, TextField, Tooltip, Typography } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { KeyboardEventHandler, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";


export type AccordionWithTextFieldAndButtonProps = {
    disabled: boolean
    summaryTooltip: string
    summaryTextLeft: string
    summaryCounter?: number | undefined
    summaryCounterColor?: string | undefined
    expanded: boolean
    onExpandedChange: () => void
    onClick: (text: string) => Promise<void>
    textfieldEmptyText: string
    validateText?: ((text: string) => string) | undefined
    buttonText: string
    buttonTooltip: string
    clickableLink?: string | undefined
    clickableLinkText?: string | undefined
    children?: JSX.Element[] | JSX.Element
}

export const AccordionWithTextFieldAndButton = (props: AccordionWithTextFieldAndButtonProps) => {
    const {
        disabled,
        summaryTooltip,
        summaryTextLeft,
        summaryCounter,
        summaryCounterColor,
        expanded,
        onExpandedChange,
        onClick,
        textfieldEmptyText,
        validateText,
        buttonText,
        buttonTooltip,
        clickableLink,
        clickableLinkText,
        children
    } = props;

    const { t } = useTranslation();

    const [text, setText] = useState('');
    const [clickError, setClickError] = useState('');
    const [isProcessingClick, setIsProcessingClick] = useState(false);
    const validationError = useMemo(() => validateText ? validateText(text) : '', [text, validateText]);

    //if text changes, the click error should be reset
    useEffect(() => {
        setClickError('');
    }, [text]);

    const error = clickError !== '' ? clickError : validationError;

    const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            props.onClick(text);
        }
    }, [text, props]);

    const handleClick = useCallback(() => {
        setIsProcessingClick(true);
        onClick(text)
            .then(() => setClickError(''))
            .catch((e) => {
                setClickError(t(e))
            })
            .finally(() => setIsProcessingClick(false));
    }, [onClick, t, text]);

    return <Accordion
        disabled={disabled}
        expanded={expanded}
        onChange={onExpandedChange}
        style={{ margin: 10 }}
    >
        <AccordionSummary
            title={summaryTooltip}
            expandIcon={<ExpandMore />}
        >
            <Grid container alignItems='center' justifyContent='flex-end'>
                <Grid item xs={10}>
                    <Typography style={{ fontWeight: 'bold' }}>{summaryTextLeft}</Typography>
                </Grid>
                <Grid item xs={2} style={{ textAlign: 'right' }}>
                    <Typography style={{
                        color: summaryCounterColor,
                        fontWeight: 'bold'
                    }}>
                        {summaryCounter}
                    </Typography>
                </Grid>
            </Grid>
        </AccordionSummary>
        <AccordionDetails>
            <Grid container alignItems='center' justifyContent='center' spacing={2} direction="column">
                {children}
                <Grid item style={{ width: '100%' }}>
                    <TextField
                        label={textfieldEmptyText}
                        variant="outlined"
                        value={text}
                        error={error !== ''}
                        helperText={error}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => setText(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item style={{ width: '100%' }}>
                    <Tooltip title={text === '' ? buttonTooltip : ''}>
                        <span>
                            <Button variant="contained"
                                disabled={error !== '' || isProcessingClick || text === ''}
                                color="primary"
                                onClick={handleClick}
                                fullWidth
                            >
                                {isProcessingClick ? <CircularProgress /> : buttonText}
                            </Button>
                        </span>
                    </Tooltip>
                </Grid>
                <Grid item style={{ width: '100%' }}>
                    <Typography variant='caption'>
                        <Link href={clickableLink} target="_blank" rel="noopener noreferrer" >
                            {clickableLinkText}
                        </Link>
                    </Typography>
                </Grid>
            </Grid>
        </AccordionDetails>
    </Accordion>
}
